/* General styles */
.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.app-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

/* Responsive input-group styles */
.input-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.input-group input, 
.input-group button {
  width: 100%;
  max-width: 300px;
  margin: 10px;
  padding: 10px;
  font-size: 1rem;
}

.person-card {
  background-color: #f9f9f9;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.person-name {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

/* Responsive item list */
.item-list {
  list-style-type: none;
  padding: 0;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.button, .button-delete {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-delete {
  background-color: red;
}

.button:hover, .button-delete:hover {
  opacity: 0.8;
}

/* Summary styles */
.summary {
  padding: 20px;
  background-color: #f1f1f1;
  border-radius: 5px;
}

.summary-title {
  font-size: 2rem;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  /* Mobile-specific styles */
  .app-title {
    font-size: 2rem;
  }

  .input-group {
    flex-direction: column;
  }

  .input-group input,
  .input-group button {
    width: 90%;
  }

  .person-card {
    font-size: 1rem;
  }

  .person-name {
    font-size: 1.2rem;
  }

  .summary-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  /* Smaller mobile-specific styles */
  .app-title {
    font-size: 1.5rem;
  }

  .input-group input,
  .input-group button {
    width: 100%;
  }

  .person-name {
    font-size: 1rem;
  }
}
