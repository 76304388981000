/* Container styles */
.landing-page {
    text-align: center;
    padding: 50px;
    animation: fadeIn 2s ease-in; /* Fade-in animation for the whole page */
  }
  
  /* Logo styles */
  .logo {
    width: 150px;
    margin-bottom: 20px;
    animation: logoFadeIn 2s ease-in; /* Fade-in logo animation */
  }
  
  /* Header styles */
  .landing-header h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    animation: slideInFromLeft 1s ease-out; /* Slide-in animation */
  }
  
  .landing-header p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    animation: slideInFromRight 1s ease-out; /* Slide-in animation */
  }
  
  /* Content styles */
  .landing-content h2 {
    font-size: 2rem;
    margin-bottom: 15px;
  }
  
  .landing-content p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .get-started-btn {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    animation: bounceIn 1s ease; /* Bounce animation */
  }
  
  .get-started-btn:hover {
    background-color: #0056b3;
  }
  
  /* Animations */
  
  /* Fade in the whole page */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Fade-in for the logo */
  @keyframes logoFadeIn {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Slide in from the left */
  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  /* Slide in from the right */
  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  /* Bounce-in for the button */
  @keyframes bounceIn {
    0% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  